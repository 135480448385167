import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { BookColor } from '@remento/types/book';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useInputValue } from '@/modules/form/input';
import { useAssetImageUrl } from '@/services/api/asset';
import { useBookQuery } from '@/services/api/book';

import { BookCoverForm } from '../book-cover.form';
import { BookCoverPreview } from '../components/BookCoverPreview/BookCoverPreview';
import { BookCoverPreviewMobile } from '../components/BookCoverPreview/BookCoverPreview.mobile';
import { BookPreviewScaler } from '../components/BookPreviewScaler/BookPreviewScaler';

export interface BookCoverPreviewFormContainerProps {
  coverForm: BookCoverForm;
}

export function BookCoverPreviewFormContainer({ coverForm }: BookCoverPreviewFormContainerProps) {
  const isMobile = useIsMobileViewport();
  const title = useInputValue(coverForm, 'title');
  const subtitle = useInputValue(coverForm, 'subtitle');
  const photoUrl = useInputValue(coverForm, 'photoUrl');
  const color = useInputValue(coverForm, 'color');

  const Preview = (
    <BookCoverPreview title={title} subtitle={subtitle} photoUrl={photoUrl} color={color} padding={!isMobile} />
  );

  return isMobile ? (
    <BookCoverPreviewMobile>
      <BookPreviewScaler height={210}>{Preview}</BookPreviewScaler>
    </BookCoverPreviewMobile>
  ) : (
    Preview
  );
}

export interface BookCoverPreviewContainerProps {
  bookId: string;
  padding?: boolean;
  hideSideCover?: boolean;
  ebookBanner?: boolean;
}

export function BookCoverPreviewContainer({
  bookId,
  padding,
  hideSideCover = false,
  ebookBanner = false,
}: BookCoverPreviewContainerProps) {
  const bookQuery = useBookQuery(bookId);
  const coverUrl = useAssetImageUrl(bookQuery.data?.coverAssetId ?? null, ImageAssetAlternativeType.SMALL);

  return (
    <BookCoverPreview
      title={bookQuery.data?.title ?? null}
      subtitle={bookQuery.data?.subtitle ?? null}
      color={bookQuery.data?.color ?? BookColor.FOREST}
      photoUrl={coverUrl}
      padding={padding}
      hideSideCover={hideSideCover}
      ebookBanner={ebookBanner}
    />
  );
}
