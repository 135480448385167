import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const DragIconContainer = styled.div`
  position: absolute;
  left: 8px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  margin-left: auto;
  opacity: 0;
`;

export const StyledBookStoryListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
  align-items: center;
  padding: var(--spacing-md) var(--spacing-sm) var(--spacing-md) var(--spacing-2xl);
  border-bottom: 1px solid var(--border-hairline);
  position: relative;
  transition: background-color 200ms ease-in-out;
  user-select: none;
  cursor: pointer;

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  &[data-dragging='true'] {
    z-index: 2;
    cursor: grabbing;
  }

  &[data-dragging='true']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-round);
    border: 4px solid var(--border-primary);
    background-color: var(--surface-bright);
    box-shadow: var(--elevation-bottom-4);
  }

  &:hover,
  &[data-dragging='true'] {
    ${DragIconContainer}, ${ActionsContainer} {
      opacity: 1;
    }
  }
`;

export const HiddenContainer = styled.div`
  &[data-hidden='true'] {
    opacity: 0;
  }
`;

export const ThumbnailContainer = styled.div`
  width: 56px;
  height: 56px;
  flex: 0 0 56px;
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-minimal);
  background-color: var(--color-neutral-87);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &[data-included='false'] {
    opacity: 0.5;
  }
`;

export const Thumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-minimal);
  border: 1px solid var(--border-hairline);
  z-index: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  z-index: 1;

  &[data-included='false'] {
    opacity: 0.5;
  }
`;

export const Title = styled(RMText)`
  &[data-included='false'] {
    text-decoration: line-through;
  }
`;

export const MetadataText = styled(RMText)`
  display: flex;
  align-items: center;
`;

export const PageCountLoaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const IssueWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
`;
