import { useCallback } from 'react';
import { ShippingAddressValidationErrorCode } from '@remento/types/shipping';

import { BookShippingFormOutput } from '../book-shipping.form';
import { ShippingAddressErrorDialog } from '../components/ShippingAddressErrorDialog/ShippingAddressErrorDialog';

export interface ShippingAddressErrorDialogContainerProps {
  input: BookShippingFormOutput;
  error: ShippingAddressValidationErrorCode | 'UNKNOWN' | null;
  resolvedAddress: BookShippingFormOutput | null;
  onSave: (address: BookShippingFormOutput) => void;
  onClose: () => void;
}

export function ShippingAddressErrorDialogContainer({
  input,
  error,
  resolvedAddress,
  onSave,
  onClose,
}: ShippingAddressErrorDialogContainerProps) {
  const handleContactSupport = useCallback(() => {
    const email = 'support@remento.co';
    const subject = 'Shipping address validation error';
    const body = [
      'Dear Team Remento,',
      '',
      'I have received the following error message as I attempted to ship my book.',
      '',
      'I confirm that the following address is valid:',
      '',
      `Recipient name: ${input.recipientName}`,
      `Address line 1: ${input.addressLine1}`,
      `Address line 2: ${input.addressLine2 ?? 'N/A'}`,
      `City/Town: ${input.city}`,
      `State/Region/Province: ${input.state}`,
      `Zip/Post code: ${input.zipCode}`,
      `Country: ${input.country}`,
      '',
      `Error: ${error}`,
    ].join('\n');

    // Encode the subject and body to ensure the mailto link is properly formatted
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }, [error, input]);

  return (
    <ShippingAddressErrorDialog
      input={input}
      error={error}
      resolvedAddress={resolvedAddress}
      onSave={onSave}
      onClose={onClose}
      onEditAddress={onClose}
      onContactSupport={handleContactSupport}
    />
  );
}
