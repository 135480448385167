import OpenBookSrc from '@/assets/open-book.png';
import { RMText } from '@/components/RMText/RMText';

import { Image, ImageContainer, StyledBookStoryListEmpty } from './BookStoryListEmpty.styles';

export function BookStoryListEmpty() {
  return (
    <StyledBookStoryListEmpty>
      <ImageContainer>
        <Image src={OpenBookSrc} />
      </ImageContainer>
      <RMText type="sans" size="xs" color="on-surface-tertiary">
        Stories will appear here
      </RMText>
    </StyledBookStoryListEmpty>
  );
}
