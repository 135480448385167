import { styled } from '@linaria/react';

import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
  align-items: flex-start;
`;

export const Checkbox = styled(RMCheckbox)`
  margin-top: 4px;
`;

export const CheckboxMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
