import { AclGroupRole } from '@remento/types/acl';

import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useProjectQuery } from '@/services/api/project';

import { BookQuantityForm } from '../book-quantity.form';
import { BookQuantityFormView } from '../components/BookQuantityFormView/BookQuantityFormView';

export interface BookQuantityFormViewContainerProps {
  projectId: string;
  form: BookQuantityForm;
}

const USE_CREDIT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN];

export function BookQuantityFormViewContainer({ projectId, form }: BookQuantityFormViewContainerProps) {
  // Queries
  const projectQuery = useProjectQuery(projectId);
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const canUseCredits = hasRole(USE_CREDIT_ROLES, userProjectRoles ?? []);
  const availableCredits = canUseCredits ? Math.max(projectQuery.data?.credit?.books ?? 0, 0) : 0;

  return (
    <BookQuantityFormView
      form={form}
      availableCredits={availableCredits}
      ebookAlreadyPurchased={projectQuery.data?.credit?.ebook ?? false}
    />
  );
}
