import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createBookQrCodeFormSchema() {
  return z.object({
    printQrCode: z.boolean(),
  });
}

export type BookQrCodeFormInput = z.input<ReturnType<typeof createBookQrCodeFormSchema>>;
export type BookQrCodeFormOutput = z.output<ReturnType<typeof createBookQrCodeFormSchema>>;
export type BookQrCodeForm = Form<BookQrCodeFormInput, BookQrCodeFormOutput>;

export function createBookQrCodeForm(defaultValues?: DeepPartial<BookQrCodeFormInput>): BookQrCodeForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createBookQrCodeFormSchema(),
  });
}
