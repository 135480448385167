import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ShippingAddressCountry } from '@remento/types/shipping';

import { DesktopOnly } from '@/components/DesktopOnly/DesktopOnly';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { AppLayout } from '@/layouts/AppLayout';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { setNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { RementoPage } from '@/modules/routing';
import { getRouteFromPathname } from '@/modules/routing/routes';
import { RoutePath } from '@/modules/routing/types/routing.types';

import { BookOrderContextProvider, BookOrderContextValue } from '../book-order.context';
import { createBookQuantityForm } from '../book-quantity.form';
import { createBookShippingForm } from '../book-shipping.form';

function InternalBookOrderLayoutContainer() {
  const bookOrderContextValue = useMemo<BookOrderContextValue>(
    () => ({
      quantityForm: createBookQuantityForm({
        quantity: 0,
        creditsApplied: 0,
        ebook: false,
      }),
      shippingForm: createBookShippingForm({
        country: ShippingAddressCountry.US,
      }),
    }),
    [],
  );

  return (
    <BookOrderContextProvider value={bookOrderContextValue}>
      <Outlet />
    </BookOrderContextProvider>
  );
}

export function BookOrderLayoutContainer() {
  const params = useParams();
  const navbarStore = useNavbarStore();
  const isMobile = useIsMobileViewport();

  // Routing
  const location = useLocation();
  const route = getRouteFromPathname(location.pathname);

  useEffect(() => {
    setNavbarProjectId(navbarStore, params.projectId ?? '');
  }, [navbarStore, params.projectId]);

  return (
    <RementoPage type="default" loader={<PageLoader layout="navbar" />}>
      <AppLayout>
        {isMobile && route != RoutePath.BookOrderCoverDesign ? (
          <DesktopOnly Header={<MobilePageHeaderContainer />} />
        ) : (
          <InternalBookOrderLayoutContainer />
        )}
      </AppLayout>
    </RementoPage>
  );
}
