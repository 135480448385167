import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const StyledBookCoverPhotoUpload = styled.div`
  padding: var(--spacing-md);
  border-radius: var(--radius-regular);
  border: 1px solid var(--border-strong);
  display: flex;
  gap: var(--spacing-md);
  position: relative;
`;

export const Photo = styled.img`
  max-width: 48px;
  max-height: 64px;
  border-radius: var(--radius-minimal);
  object-fit: cover;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xs);
  width: 100%;
`;

export const ActionDivider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Action = styled.button`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  width: fit-content;
`;

export const RemoveButton = styled(RMIconButton)`
  position: absolute;
  top: -8px;
  right: -8px;
`;
