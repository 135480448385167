import { useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';

import {
  Body,
  CheckboxWrapper,
  MessageBox,
  MessageBoxBody,
  MessageBoxIcon,
} from './BookQrCodeConfirmationDialog.styles';

export interface BookQrCodeConfirmationDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export function BookQrCodeConfirmationDialog({ onCancel, onConfirm }: BookQrCodeConfirmationDialogProps) {
  const [checked, setChecked] = useState(false);

  return (
    <RMDialog.Root open onClose={onCancel}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Are you sure?"
          message="By removing QR codes, you’ll lose the link between your book and the recording used to write each chapter."
          rightAdornment={<RMCloseButton onClick={onCancel} />}
        />
        <RMDialog.Body>
          <Body>
            <MessageBox>
              <MessageBoxIcon icon={faInfoCircle} color="var(--on-surface-primary)" />
              <MessageBoxBody>
                <RMText type="sans" size="s" color="on-surface-primary" bold>
                  Did you know...
                </RMText>
                <RMText type="sans" size="s" color="on-surface-primary">
                  80% of children and grandchildren say the ability to scan a QR code and watch their storyteller’s
                  recording is their favorite part of reading a Remento Book?
                </RMText>
              </MessageBoxBody>
            </MessageBox>
            <CheckboxWrapper>
              <RMCheckbox square size="sm" bold={false} checked={checked} onChange={setChecked} />
              <RMText type="sans" size="s" color="on-surface-primary">
                I confirm that I want to remove QR codes.
              </RMText>
            </CheckboxWrapper>
          </Body>
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton onClick={onCancel}>Cancel</RMButton>
          <RMButton background="danger" disabled={checked == false} autoLoading onClick={onConfirm}>
            Remove QR codes
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
