import { PdfDocumentAssetAlternativeType, PdfDocumentMetadata } from '@remento/types/alternative';
import { ShippingAddressCountry } from '@remento/types/shipping';

import { useInputValue } from '@/modules/form/input';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useBookPreviewAssetIdQuery } from '@/services/api/book';
import { useProjectQuery } from '@/services/api/project';

import { BookQuantityForm } from '../book-quantity.form';
import { BookShippingForm } from '../book-shipping.form';
import { BookOrderSummary } from '../components/BookOrderSummary/BookOrderSummary';

import { BookCoverPreviewContainer } from './BookCoverPreview.container';

export const LARGE_BOOK_PAGE_THRESHOLD = 200;

export interface BookOrderSummaryContainerProps {
  projectId: string;
  bookId: string;
  quantityForm: BookQuantityForm;
  shippingForm: BookShippingForm;
}

export function BookOrderSummaryContainer({
  projectId,
  bookId,
  quantityForm,
  shippingForm,
}: BookOrderSummaryContainerProps) {
  // Queries
  const projectQuery = useProjectQuery(projectId);
  const bookPreviewAssetIdQuery = useBookPreviewAssetIdQuery(bookId);

  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookPreviewAssetIdQuery.data ?? null);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    PdfDocumentAssetAlternativeType.PREVIEW,
  );
  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;

  // State
  const quantity = useInputValue(quantityForm, 'quantity') ?? 0;
  const creditsApplied = useInputValue(quantityForm, 'creditsApplied');
  const ebook = useInputValue(quantityForm, 'ebook');
  const shippingCountry = useInputValue(shippingForm, 'country');

  return (
    <BookOrderSummary
      quantity={quantity ?? 0}
      hasExtraFee={bookPageCount > LARGE_BOOK_PAGE_THRESHOLD}
      credits={creditsApplied ?? 0}
      ebook={ebook ?? false}
      ebookAlreadyPurchased={projectQuery.data?.credit?.ebook ?? false}
      shippingCountry={shippingCountry ?? ShippingAddressCountry.US}
      BookCoverPreview={
        <BookCoverPreviewContainer
          bookId={bookId}
          padding={false}
          hideSideCover={quantity == 0 && ebook == true}
          ebookBanner={quantity == 0 && ebook == true}
        />
      }
    />
  );
}
