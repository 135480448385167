import { useCallback } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

export type StorySummaryMode = 'closed' | 'edit' | 'view';

export interface BookStoriesState {
  stories: Array<{
    storyId: string;
    included: boolean;
  }>;
}

export type BookStoriesStore = StoreApi<BookStoriesState>;

export function createBookStoriesStore(): BookStoriesStore {
  return createStore<BookStoriesState>(() => ({
    stories: [],
  }));
}

export function setBookStoriesIds(store: BookStoriesStore, storiesIds: string[], includedStoriesIds: string[]): void {
  store.setState((state) => {
    const includedStoriesIdsSet = new Set(includedStoriesIds);
    const newStories = storiesIds.map((storyId) => ({
      storyId,
      included: includedStoriesIdsSet.has(storyId),
    }));

    return {
      ...state,
      stories: newStories,
    };
  });
}

export function useBookStoriesIds(store: BookStoriesStore): string[] {
  return useStore(
    store,
    useCallback(({ stories }) => {
      return stories.map((s) => s.storyId);
    }, []),
  );
}
export function useIncludedBookStoriesIds(store: BookStoriesStore): string[] {
  return useStore(
    store,
    useCallback(({ stories }) => {
      return stories.filter((s) => s.included).map((s) => s.storyId);
    }, []),
  );
}

export function useIncludedBookStoriesCount(store: BookStoriesStore): number {
  return useStore(
    store,
    useCallback(({ stories }) => {
      return stories.filter((s) => s.included).length;
    }, []),
  );
}

export function getIncludedBookStoriesIds(store: BookStoriesStore): string[] {
  const state = store.getState();
  return state.stories.filter((s) => s.included).map((s) => s.storyId);
}

export function moveBookStory(store: BookStoriesStore, storyId: string, newStoryIdPosition: string): void {
  store.setState((state) => {
    // Check if the provided IDs are valid
    const currentIndex = state.stories.findIndex((s) => s.storyId === storyId);
    const newIndex = state.stories.findIndex((s) => s.storyId === newStoryIdPosition);
    if (currentIndex === -1 || newIndex === -1) {
      return state;
    }

    const newStories = [...state.stories];
    const story = newStories[currentIndex];

    // Remove the id from its current position
    newStories.splice(currentIndex, 1);

    // Insert the id at the new position
    newStories.splice(newIndex, 0, story);

    return {
      ...state,
      stories: newStories,
    };
  });
}

export function useBookStoryIndex(store: BookStoriesStore, storyId: string): number {
  return useStore(
    store,
    useCallback(
      ({ stories }) => {
        // TODO: This is called for every story rendered, making this O(n)^2.
        // We should improve this.
        let excludedStoriesCount = 0;
        for (let i = 0; i < stories.length; i += 1) {
          if (stories[i].storyId === storyId) {
            return i - excludedStoriesCount;
          }
          if (stories[i].included === false) {
            excludedStoriesCount += 1;
          }
        }
        return -1;
      },
      [storyId],
    ),
  );
}

export function useIsBookStoryIncluded(store: BookStoriesStore, storyId: string): boolean {
  return useStore(
    store,
    useCallback(
      ({ stories }) => {
        // TODO: This is called for every story rendered, making this O(n)^2.
        // We should improve this.
        for (const story of stories) {
          if (story.storyId === storyId) {
            return story.included;
          }
        }
        return false;
      },
      [storyId],
    ),
  );
}

export function toggleBookStoryIncluded(store: BookStoriesStore, storyId: string): void {
  store.setState((state) => {
    const newStories = state.stories.map((s) => {
      if (s.storyId === storyId) {
        return {
          storyId,
          included: !s.included,
        };
      }
      return s;
    });

    return {
      ...state,
      stories: newStories,
    };
  });
}
