import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createBookQuantityFormSchema() {
  return z
    .object({
      quantity: z.number().int(),
      creditsApplied: z.number().int(),
      ebook: z.boolean(),
    })
    .refine(({ quantity, ebook }) => {
      return ebook == true || quantity > 0;
    });
}

export type BookQuantityFormInput = z.input<ReturnType<typeof createBookQuantityFormSchema>>;
export type BookQuantityFormOutput = z.output<ReturnType<typeof createBookQuantityFormSchema>>;
export type BookQuantityForm = Form<BookQuantityFormInput, BookQuantityFormOutput>;

export function createBookQuantityForm(defaultValues?: DeepPartial<BookQuantityFormInput>): BookQuantityForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createBookQuantityFormSchema(),
  });
}
