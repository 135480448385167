import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const StyledBookQuantityFormView = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const EbookCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: var(--radius-round);
  background-color: var(--surface-bright);
  border: 2px solid var(--border-primary);
  white-space: preserve;
  transition: background-color 200ms ease-in-out;

  &[data-checked='false'] {
    background-color: transparent;
    border: 1px solid var(--border-strong);
    margin: 1px;
  }
`;

export const EbookCheckboxDescription = styled(RMText)`
  padding-left: 1.625rem;
`;
