import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ShippingAddressCountry } from '@remento/types/shipping';

import { DesktopOnly } from '@/components/DesktopOnly/DesktopOnly';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { AppLayout } from '@/layouts/AppLayout';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { setNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { getStoriesPath, RementoPage } from '@/modules/routing';
import { useBookOrderQuery } from '@/services/api/book';

import { BookOrderContextProvider, BookOrderContextValue } from '../book-order.context';
import { createBookQuantityForm } from '../book-quantity.form';
import { createBookShippingForm } from '../book-shipping.form';
import { BookReorderLayout } from '../components/BookReorderLayout/BookReorderLayout';

interface InternalBookReorderLayoutContainerProps {
  projectId: string;
  bookOrderId: string;
}

function InternalBookReorderLayoutContainer({ projectId, bookOrderId }: InternalBookReorderLayoutContainerProps) {
  const navigate = useNavigate();

  const bookOrderQuery = useBookOrderQuery(bookOrderId);

  const bookOrderContextValue = useMemo<BookOrderContextValue>(
    () => ({
      quantityForm: createBookQuantityForm({
        quantity: 0,
        creditsApplied: 0,
        ebook: false,
      }),
      shippingForm: createBookShippingForm({
        country: ShippingAddressCountry.US,
      }),
    }),
    [],
  );

  const handleGoToProject = useCallback(() => {
    navigate(getStoriesPath(projectId));
  }, [navigate, projectId]);

  if (bookOrderQuery.data == null) {
    return <PageLoader />;
  }

  return (
    <BookOrderContextProvider value={bookOrderContextValue}>
      <BookReorderLayout bookTitle={bookOrderQuery.data.title} onGoToProject={handleGoToProject}>
        <Outlet />
      </BookReorderLayout>
    </BookOrderContextProvider>
  );
}

export function BookReorderLayoutContainer() {
  const params = useParams();
  const navbarStore = useNavbarStore();
  const isMobile = useIsMobileViewport();

  useEffect(() => {
    setNavbarProjectId(navbarStore, params.projectId ?? '');
  }, [navbarStore, params.projectId]);

  return (
    <RementoPage type="default" loader={<PageLoader layout="navbar" />}>
      {isMobile && (
        <>
          <AppLayout>
            <DesktopOnly Header={<MobilePageHeaderContainer />} />
          </AppLayout>
        </>
      )}
      {isMobile == false && (
        <InternalBookReorderLayoutContainer projectId={params.projectId ?? ''} bookOrderId={params.bookOrderId ?? ''} />
      )}
    </RementoPage>
  );
}
