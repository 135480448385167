import { styled } from '@linaria/react';

export const StyledBookOrderSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-xl);
  background-color: var(--surface-dim);
  border-radius: var(--radius-regular);
  width: 21.3125rem;

  body[data-tablet='true'] & {
    width: 100%;
  }
`;

export const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const SummaryTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const SummaryTableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-weak);
  padding-bottom: var(--spacing-xs);
`;

export const SummaryTableItemLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--on-surface-primary);
`;

export const SubtotalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BookPreviewContainer = styled.div`
  position: relative;
  margin-bottom: var(--spacing-xs);
`;
