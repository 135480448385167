import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMPage } from '@/components/RMPage';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { logger } from '@/logger';
import { useIsFormValid } from '@/modules/form/form';
import {
  getBookOrderCoverDesignPath,
  getBookOrderFinalizePath,
  getBookOrderPreviewPath,
  getBookOrderQuantityPath,
  getBookOrderStoriesPath,
} from '@/modules/routing';
import { useBookQuery } from '@/services/api/book';

import { BookQuantityForm } from '../book-quantity.form';

// eslint-disable-next-line react-refresh/only-export-components
export enum BookOrderHeaderStep {
  DesignCover = 0,
  ReviewStories = 1,
  Preview = 2,
  Quantity = 3,
  Checkout = 4,
}

export const BookOrderStepName: Record<BookOrderHeaderStep, string> = {
  [BookOrderHeaderStep.DesignCover]: 'Design cover',
  [BookOrderHeaderStep.ReviewStories]: 'Review stories',
  [BookOrderHeaderStep.Preview]: 'Preview book',
  [BookOrderHeaderStep.Quantity]: 'Order details',
  [BookOrderHeaderStep.Checkout]: 'Finalize',
};

export interface BookOrderHeaderContainerProps {
  projectId: string;
  bookId: string;
  step: BookOrderHeaderStep;
  quantityForm: BookQuantityForm;
}

export function BookOrderHeaderContainer({ projectId, bookId, step, quantityForm }: BookOrderHeaderContainerProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // Queries
  const bookQuery = useBookQuery(bookId);

  // State
  const isQuantityFormValid = useIsFormValid(quantityForm);

  const steps = useMemo(() => {
    const book = bookQuery.data;
    const designCoverInvalid = !book || book?.title == null || book?.color == null;
    const storiesInvalid = designCoverInvalid || book.storyIds.length == 0;
    const previewInvalid = storiesInvalid || book.pdfAssetId == null;
    const quantityInvalid = previewInvalid || isQuantityFormValid == false;

    return [
      { label: BookOrderStepName[BookOrderHeaderStep.DesignCover] },
      { label: BookOrderStepName[BookOrderHeaderStep.ReviewStories], disabled: designCoverInvalid },
      { label: BookOrderStepName[BookOrderHeaderStep.Preview], disabled: storiesInvalid },
      { label: BookOrderStepName[BookOrderHeaderStep.Quantity], disabled: previewInvalid },
      { label: BookOrderStepName[BookOrderHeaderStep.Checkout], disabled: quantityInvalid },
    ];
  }, [bookQuery.data, isQuantityFormValid]);

  const handleStepChange = useCallback(
    (newStep: BookOrderHeaderStep) => {
      if (newStep === step) {
        return;
      }

      if (newStep === BookOrderHeaderStep.DesignCover) {
        navigate(getBookOrderCoverDesignPath(projectId, bookId));
        return;
      }

      const book = bookQuery.data;
      if (!book) {
        logger.warn('BOOK_NOT_LOADED');
        return;
      }

      if (book?.title == null || book?.color == null) {
        return;
      }

      switch (newStep) {
        case BookOrderHeaderStep.ReviewStories:
          navigate(getBookOrderStoriesPath(projectId, bookId));
          return;
        case BookOrderHeaderStep.Preview:
          navigate(getBookOrderPreviewPath(projectId, bookId));
          return;
      }

      if (book.storyIds.length == 0 || book.pdfAssetId == null) {
        return;
      }

      if (newStep === BookOrderHeaderStep.Quantity) {
        navigate(getBookOrderQuantityPath(projectId, bookId));
        return;
      }

      if (isQuantityFormValid == null) {
        return;
      }

      navigate(getBookOrderFinalizePath(projectId, bookId));
    },
    [bookId, bookQuery.data, isQuantityFormValid, navigate, projectId, step],
  );

  const stepName = BookOrderStepName[step];

  return (
    <>
      <RMPage.Header>
        {isMobile ? (
          <div style={{ width: '100%' }}>
            <RMText size="xxs" type="sans" bold color="on-surface-secondary">
              {step + 1}: {stepName}
            </RMText>
            <RMStepper layout="progress" activeStepIndex={step} steps={steps} onStepChange={handleStepChange} />
          </div>
        ) : (
          <RMStepper layout="stepper" activeStepIndex={step} steps={steps} onStepChange={handleStepChange} />
        )}
      </RMPage.Header>
    </>
  );
}
