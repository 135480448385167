import { PropsWithChildren, useCallback } from 'react';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { useDndSensors } from '@/hooks/useDndSensors';

export type BookStoryListRootProps = PropsWithChildren<{
  storiesIds: string[];
  onMove: (draggedId: string, droppedId: string) => void;
}>;

export function BookStoryListRoot({ storiesIds, onMove, children }: BookStoryListRootProps) {
  const sensors = useDndSensors();

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        onMove(active.id as string, over.id as string);
      }
    },
    [onMove],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={storiesIds} strategy={verticalListSortingStrategy}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
      </SortableContext>
    </DndContext>
  );
}
