import { styled } from '@linaria/react';

export const StyledBookStoryListEmpty = styled.div`
  border: 1px solid var(--border-weak);
  gap: var(--spacing-lg);
  padding: var(--spacing-2xl) 0;
  background-color: var(--surface-dim);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  border-radius: 100%;
  height: 12.25rem;
  width: 12.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface);
`;

export const Image = styled.img``;
