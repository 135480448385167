import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  gap: var(--spacing-4xl);
  height: 100%;

  body[data-tablet='true'] & {
    flex-direction: column-reverse;
    gap: var(--spacing-2xl);
  }
`;

export const LeftPanel = styled.div`
  width: 45%;

  body[data-tablet='true'] & {
    width: 32.5rem;
    flex-grow: 1;
  }
`;

export const RightPanel = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  width: 55%;
  overflow: auto;

  body[data-tablet='true'] & {
    width: 32.5rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: var(--spacing-lg);
`;
