import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMPage } from '@/components/RMPage';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { useIsFormValid } from '@/modules/form/form';
import { getBookReorderFinalizePath, getBookReorderPreviewPath, getBookReorderQuantityPath } from '@/modules/routing';

import { BookQuantityForm } from '../book-quantity.form';

// eslint-disable-next-line react-refresh/only-export-components
export enum BookReorderHeaderStep {
  Preview = 0,
  Quantity = 1,
  Checkout = 2,
}

const BookReorderStepName: Record<BookReorderHeaderStep, string> = {
  [BookReorderHeaderStep.Preview]: 'Preview book',
  [BookReorderHeaderStep.Quantity]: 'Order details',
  [BookReorderHeaderStep.Checkout]: 'Finalize',
};

export interface BookReorderHeaderContainerProps {
  projectId: string;
  bookOrderId: string;
  step: BookReorderHeaderStep;
  quantityForm: BookQuantityForm;
}

export function BookReorderHeaderContainer({
  projectId,
  bookOrderId,
  step,
  quantityForm,
}: BookReorderHeaderContainerProps) {
  const navigate = useNavigate();

  // State
  const isQuantityFormValid = useIsFormValid(quantityForm);

  const steps = useMemo(() => {
    return [
      { label: BookReorderStepName[BookReorderHeaderStep.Preview] },
      { label: BookReorderStepName[BookReorderHeaderStep.Quantity] },
      { label: BookReorderStepName[BookReorderHeaderStep.Checkout], disabled: isQuantityFormValid == false },
    ];
  }, [isQuantityFormValid]);

  const handleStepChange = useCallback(
    (newStep: BookReorderHeaderStep) => {
      if (newStep === step) {
        return;
      }

      switch (newStep) {
        case BookReorderHeaderStep.Preview:
          navigate(getBookReorderPreviewPath(projectId, bookOrderId));
          return;
        case BookReorderHeaderStep.Quantity:
          navigate(getBookReorderQuantityPath(projectId, bookOrderId));
          return;
      }

      if (isQuantityFormValid == null) {
        return;
      }

      navigate(getBookReorderFinalizePath(projectId, bookOrderId));
    },
    [bookOrderId, isQuantityFormValid, navigate, projectId, step],
  );

  return (
    <RMPage.Header>
      <RMStepper layout="stepper" activeStepIndex={step} steps={steps} onStepChange={handleStepChange} />
    </RMPage.Header>
  );
}
