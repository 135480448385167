import { useMemo } from 'react';

import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMSelect } from '@/components/RMSelect';
import { RMText } from '@/components/RMText/RMText';
import { InputController } from '@/modules/form/container';
import { setInputValue, useInputState, useInputValue } from '@/modules/form/input';

import { BookQuantityForm } from '../../book-quantity.form';

import {
  EbookCheckboxDescription,
  EbookCheckboxWrapper,
  FormField,
  StyledBookQuantityFormView,
} from './BookQuantityFormView.styles';

const QUANTITY_OPTIONS = [
  {
    label: 'Select',
    value: '-1',
    disabled: true,
  },
  {
    label: '0',
    value: '0',
  },
  ...Array.from({ length: 100 }).map((_, i) => ({ label: `${i + 1}`, value: `${i + 1}` })),
];

export interface BookQuantityFormView {
  form: BookQuantityForm;
  availableCredits: number;
  ebookAlreadyPurchased: boolean;
}

export function BookQuantityFormView({ form, availableCredits, ebookAlreadyPurchased }: BookQuantityFormView) {
  const quantity = useInputValue(form, 'quantity') ?? 0;
  const quantityState = useInputState(form, 'quantity');

  const creditOptions = useMemo(() => {
    if (availableCredits == 0) {
      return [];
    }

    return [
      {
        label: 'Select',
        value: '0',
      },
      ...Array.from({ length: Math.min(availableCredits, quantity) }).map((_, index) => ({
        label: String(index + 1),
        value: String(index + 1),
      })),
    ];
  }, [availableCredits, quantity]);

  return (
    <StyledBookQuantityFormView>
      <FormField>
        <RMText type="serif" size="l" color="on-surface-primary">
          How many books would you like to order?
        </RMText>

        <InputController form={form} path="quantity">
          {({ value, onChange, onBlur }) => (
            <RMSelect
              id="quantity-select"
              label="Select hardcover book quantity"
              options={QUANTITY_OPTIONS}
              value={value != null && (value != 0 || quantityState.isTouched) ? String(value) : '-1'}
              onChange={(newValue) => {
                const parsedNewValue = parseInt(newValue == '-1' ? '0' : newValue, 10);
                onChange(parsedNewValue);
                setInputValue(form, 'creditsApplied', Math.min(parsedNewValue, availableCredits));
                onBlur();
              }}
            />
          )}
        </InputController>
      </FormField>

      {availableCredits > 0 && (
        <FormField>
          <RMText type="serif" size="l" color="on-surface-primary">
            Would you like to apply credits to this order?
          </RMText>

          <InputController form={form} path="creditsApplied">
            {({ value, onChange, onBlur }) => {
              return (
                <RMSelect
                  id="credits-select"
                  label="Select number of book credits to apply"
                  disabled={quantity == 0}
                  options={creditOptions}
                  value={value != null ? String(value) : '0'}
                  onChange={(newValue) => {
                    onChange(parseInt(newValue, 10));
                    onBlur();
                  }}
                />
              );
            }}
          </InputController>
        </FormField>
      )}

      <FormField>
        <RMText type="serif" size="l" color="on-surface-primary">
          Would you like to generate an e-book?
        </RMText>

        <InputController form={form} path="ebook">
          {({ value, onChange, onBlur }) => {
            return (
              <EbookCheckboxWrapper data-checked={value}>
                <RMCheckbox
                  square
                  size="sm"
                  label={`Generate an e-book ${ebookAlreadyPurchased ? '(already purchased)' : '     $49.99'}`}
                  checked={value ?? false}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <EbookCheckboxDescription type="sans" size="xs" color="on-surface-primary">
                  Expand the reach of your stories. Create an e-book version of this book to have it available in a
                  digital format.
                </EbookCheckboxDescription>
              </EbookCheckboxWrapper>
            );
          }}
        </InputController>
      </FormField>
    </StyledBookQuantityFormView>
  );
}
