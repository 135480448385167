import { useMemo } from 'react';
import { PointerSensor, SensorDescriptor, SensorOptions, TouchSensor } from '@dnd-kit/core';

import { useIsMobileViewport } from './useIsMobileViewport';

export function useDndSensors() {
  const isMobile = useIsMobileViewport();
  return useMemo<SensorDescriptor<SensorOptions>[]>(() => {
    const options = {
      activationConstraint: {
        distance: 4,
      },
    };

    const isTouchscreen = window.matchMedia('(pointer: coarse)').matches;
    // PointerSensor also works with touch screens devices, but for small and touch devices
    // the  TouchSensor works way better.
    return [{ sensor: isMobile && isTouchscreen ? TouchSensor : PointerSensor, options }];
  }, [isMobile]);
}
