import { PropsWithChildren } from 'react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';

import { BackButton, Sidebar, SidebarContent, StyledBookReorderLayout } from './BookReorderLayout.styles';

export type BookReorderLayout = PropsWithChildren<{
  bookTitle: string;
  onGoToProject: () => void;
}>;

export function BookReorderLayout({ bookTitle, onGoToProject, children }: BookReorderLayout) {
  return (
    <StyledBookReorderLayout>
      <Sidebar>
        <BackButton onClick={onGoToProject}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <RMText type="sans" size="xs" color="primary" bold>
            Back to project
          </RMText>
        </BackButton>
        <SidebarContent>
          <RMText type="sans" size="xxs" color="on-surface-secondary" bold trackedOutCaps>
            REORDER
          </RMText>
          <RMText type="sans" size="m" color="on-surface-primary" bold>
            {bookTitle}
          </RMText>
        </SidebarContent>
      </Sidebar>
      {children}
    </StyledBookReorderLayout>
  );
}
