import { BookColor, BookColorMap, BookColorNameMap } from '@remento/types/book';

import { RMColorSwatch } from '@/components/RMColorSwatch';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMText } from '@/components/RMText/RMText';
import { InputContainer, InputController } from '@/modules/form/container';
import { useControlledInputProps, useInputValue } from '@/modules/form/input';

import { BookCoverForm } from '../../book-cover.form';
import { BookCoverPhotoUpload } from '../BookCoverPhotoUpload/BookCoverPhotoUpload';

import { FormField, StyledBookCoverFormView } from './BookCoverFormView.styles';

interface BookCoverPhotoUploadFormFieldProps {
  form: BookCoverForm;
}

function BookCoverPhotoUploadFormField({ form }: BookCoverPhotoUploadFormFieldProps) {
  const handleInputProps = useControlledInputProps(form, 'photoHandle');
  const urlInputProps = useControlledInputProps(form, 'photoUrl');
  const urlInputValue = useInputValue(form, 'photoUrl');

  return (
    <FormField>
      <RMText type="sans" size="xs" color="on-surface-secondary" bold>
        {urlInputValue ? 'Cover photo' : 'Add a cover photo'}
      </RMText>
      <BookCoverPhotoUpload
        photoSrc={urlInputValue ?? null}
        onChange={(newValue) => {
          handleInputProps.onChange(newValue?.handle ?? null);
          handleInputProps.onBlur();
          urlInputProps.onChange(newValue?.url ?? null);
          urlInputProps.onBlur();
        }}
      />
    </FormField>
  );
}

export interface BookCoverFormViewProps {
  form: BookCoverForm;
}

export function BookCoverFormView({ form }: BookCoverFormViewProps) {
  const colors = Object.keys(BookColorMap) as BookColor[];

  return (
    <StyledBookCoverFormView>
      <BookCoverPhotoUploadFormField form={form} />
      <InputController form={form} path="color">
        {({ value, onChange, onBlur }) => (
          <FormField>
            <RMText type="sans" size="xs" color="on-surface-secondary">
              <b>Color:</b> {value ? BookColorNameMap[value] : null}
            </RMText>
            <RMColorSwatch.Root>
              {colors.map((color) => (
                <RMColorSwatch.Item
                  key={color}
                  colorName={BookColorNameMap[color]}
                  color={BookColorMap[color]}
                  active={value === color}
                  onClick={() => {
                    onChange(color);
                    onBlur();
                  }}
                />
              ))}
            </RMColorSwatch.Root>
          </FormField>
        )}
      </InputController>

      <InputContainer form={form} path="title">
        {(props) => <RMInput id="book-title" label="Title" textarea rows={4} {...props} />}
      </InputContainer>

      <InputContainer form={form} path="subtitle">
        {(props) => <RMInput id="book-subtitle" label="Subtitle" {...props} />}
      </InputContainer>
    </StyledBookCoverFormView>
  );
}
