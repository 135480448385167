import { createContext, PropsWithChildren, useContext } from 'react';

import { BookQuantityForm } from './book-quantity.form';
import { BookShippingForm } from './book-shipping.form';

export interface BookOrderContextValue {
  quantityForm: BookQuantityForm;
  shippingForm: BookShippingForm;
}

const BookOrderContext = createContext<BookOrderContextValue | null>(null);

export type BookOrderContextProviderProps = PropsWithChildren<{
  value: BookOrderContextValue;
}>;

export function BookOrderContextProvider({ value, children }: BookOrderContextProviderProps) {
  return <BookOrderContext.Provider value={value}>{children}</BookOrderContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBookOrderContext(): BookOrderContextValue {
  const context = useContext(BookOrderContext);
  if (!context) {
    throw new Error('useBookOrderContext must be used inside a BookOrderContextProvider');
  }
  return context;
}
