import { styled } from '@linaria/react';

export const StyledBookReorderLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  padding: var(--spacing-2xl) var(--spacing-xl);
  background-color: var(--surface-dim);
  border-right: 1px solid var(--border-weak);
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-4xl);
  padding-left: var(--spacing-sm);
`;

export const BackButton = styled.button`
  display: flex;
  gap: var(--spacing-xs);
  color: var(--primary);
  padding: var(--spacing-2xs) var(--spacing-sm);
  border-radius: var(--radius-minimal);

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  :active {
    background-color: var(--darken-neutral-press);
  }
`;
