import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseAssetAlternativeType, PdfDocumentMetadata } from '@remento/types/alternative';
import { BookColor } from '@remento/types/book';
import { StoryStatus } from '@remento/types/story';

import { useFormValue } from '@/modules/form/form';
import { useInputValue } from '@/modules/form/input';
import { getBookOrderCoverDesignPath } from '@/modules/routing';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useBookPreviewAssetIdQuery, useBookQuery } from '@/services/api/book';
import { useFilterStoriesIds, useProjectStoriesQuery } from '@/services/api/story';

import { BookQuantityForm } from '../book-quantity.form';
import { BookShippingForm } from '../book-shipping.form';
import { BookOrderCheckoutReview } from '../components/BookOrderCheckoutReview/BookOrderCheckoutReview';

export interface BookOrderCheckoutReviewContainerProps {
  projectId: string;
  bookId: string;
  quantityForm: BookQuantityForm;
  shippingForm: BookShippingForm;
  reviewed: boolean;
  onReviewedChange: (reviewed: boolean) => void;
  onEditShippingAddress: () => void;
}

export function BookOrderCheckoutReviewContainer({
  projectId,
  bookId,
  quantityForm,
  shippingForm,
  reviewed,
  onReviewedChange,
  onEditShippingAddress,
}: BookOrderCheckoutReviewContainerProps) {
  const navigate = useNavigate();

  // Queries
  const bookQuery = useBookQuery(bookId);

  const storiesQuery = useProjectStoriesQuery(projectId);
  const recordedStoriesIds = useFilterStoriesIds(storiesQuery.data ?? null, StoryStatus.RECORDED);

  const bookPreviewAssetIdQuery = useBookPreviewAssetIdQuery(bookId);
  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookPreviewAssetIdQuery.data ?? null);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    BaseAssetAlternativeType.ORIGINAL,
  );
  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;

  // State
  const hardcoverQuantity = useInputValue(quantityForm, 'quantity') ?? 0;
  const shippingFormValue = useFormValue(shippingForm);

  // Callbacks
  const handleEditBook = useCallback(() => {
    navigate(getBookOrderCoverDesignPath(projectId, bookId));
  }, [bookId, navigate, projectId]);

  if (bookQuery.data == null) {
    return null;
  }

  return (
    <BookOrderCheckoutReview
      title={bookQuery.data.title ?? ''}
      subtitle={bookQuery.data.subtitle}
      color={bookQuery.data.color ?? BookColor.FOREST}
      includedStories={bookQuery.data.storyIds.length}
      totalStories={recordedStoriesIds?.length ?? 0}
      pageCount={bookPageCount}
      shippingAddress={hardcoverQuantity > 0 ? shippingFormValue : null}
      reviewed={reviewed}
      onReviewedChange={onReviewedChange}
      onEditBook={handleEditBook}
      onEditShippingAddress={onEditShippingAddress}
    />
  );
}
