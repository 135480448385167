import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faCircleMinus, faCirclePlus, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical, faSpinner, faVideo, faWaveformLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { STORY_ISSUE_DESCRIPTION, STORY_ISSUE_TITLE, StoryIssueType } from '@/services/api/story';

import {
  ActionsContainer,
  DragIconContainer,
  HiddenContainer,
  IssueWrapper,
  MetadataText,
  PageCountLoaderWrapper,
  StyledBookStoryListItem,
  TextContainer,
  Thumbnail,
  ThumbnailContainer,
  Title,
} from './BookStoryListItem.styles';

export interface StoryListItemProps {
  id: string;
  title: string | null;
  recordedAt: number;
  type: 'audio' | 'video';
  index: number;
  imageSrc: string | null;
  pageCount: number | null;
  showPageCount: boolean;
  isIncluded: boolean;
  issues: StoryIssueType[] | null;
  onEdit: () => void;
  onToggle: () => void;
}

export function BookStoryListItem({
  id,
  title,
  recordedAt,
  type,
  index,
  imageSrc,
  pageCount,
  showPageCount,
  isIncluded,
  issues,
  onEdit,
  onToggle,
}: StoryListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    disabled: isIncluded === false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isUntitled = title == null || title.trim().length == 0;

  return (
    <StyledBookStoryListItem
      // DnD
      data-dragging={isDragging}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <HiddenContainer data-hidden={isIncluded === false}>
        <DragIconContainer>
          <FontAwesomeIcon icon={faGripVertical} size="sm" color="var(--primary)" />
        </DragIconContainer>
      </HiddenContainer>

      <HiddenContainer data-hidden={isIncluded === false}>
        <RMText type="serif" size="m" color="primary-decorative">
          {String(index).padStart(2, '0')}
        </RMText>
      </HiddenContainer>

      <ThumbnailContainer data-included={isIncluded}>
        {imageSrc !== null ? (
          <Thumbnail src={imageSrc} alt="Thumbnail" />
        ) : (
          <FontAwesomeIcon icon={type === 'video' ? faVideo : faWaveformLines} color="var(--on-surface-tertiary)" />
        )}
      </ThumbnailContainer>
      <TextContainer data-included={isIncluded}>
        <Title
          type="sans"
          size="s"
          bold
          color={isUntitled ? 'disabled' : 'on-surface-primary'}
          data-included={isIncluded}
        >
          {isUntitled ? 'Untitled' : title}
        </Title>
        <MetadataText type="sans" size="xs" bold="medium" color="on-surface-tertiary">
          {showPageCount && (
            <>
              {pageCount === null ? (
                <PageCountLoaderWrapper>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                  Calculating pages
                </PageCountLoaderWrapper>
              ) : (
                `${pageCount} page${pageCount > 1 ? 's' : ''}`
              )}
              &nbsp;·&nbsp;
            </>
          )}
          Recorded {dayjs(recordedAt).format('MMM D, YYYY')}
        </MetadataText>
        {issues != null && issues.length > 0 && (
          <>
            {issues.map((issue) => (
              <RMTooltip key={issue} message={STORY_ISSUE_DESCRIPTION[issue]} side="top" align="start">
                <IssueWrapper>
                  <FontAwesomeIcon icon={faTriangleExclamation} color="var(--caution)" />
                  <RMText type="sans" size="xs" color="caution" bold>
                    {STORY_ISSUE_TITLE[issue]}
                  </RMText>
                </IssueWrapper>
              </RMTooltip>
            ))}
          </>
        )}
      </TextContainer>
      <ActionsContainer>
        <HiddenContainer data-hidden={isIncluded === false}>
          <RMButton size="extra-small" background="outlined" onClick={onEdit}>
            Edit
          </RMButton>
        </HiddenContainer>
        <RMIconButton
          tooltip={{ label: isIncluded ? "Don't include story" : 'Include story', position: 'top' }}
          icon={isIncluded ? faCircleMinus : faCirclePlus}
          backgroundColor="transparent"
          onClick={onToggle}
        />
      </ActionsContainer>
    </StyledBookStoryListItem>
  );
}
