import { BookColor } from '@remento/types/book';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export interface BookCoverFormInput {
  photoUrl?: string | null;
  photoHandle?: string | null;
  color: BookColor;
  title: string;
  subtitle: string;
}

export interface BookCoverFormOutput {
  photoUrl?: string | null;
  photoHandle?: string | null;
  color: BookColor;
  title: string;
  subtitle: string;
}

export type BookCoverForm = Form<BookCoverFormInput, BookCoverFormOutput>;

export function createBookCoverFormSchema() {
  return z.object({
    photoUrl: z.string().nullable().optional(),
    photoHandle: z.string().nullable().optional(),
    color: z.nativeEnum(BookColor),
    title: z
      .string()
      .min(1, 'This is a required field')
      .refine(
        (data) => {
          const lines = data.split('\n');
          if (lines.length > 4) {
            return false;
          }
          return lines.every((line) => line.length <= 20);
        },
        { message: 'The title can only have up to 4 lines of text, with up to 20 characters per line.' },
      ),
    subtitle: z.string().max(30, 'The subtitle can only contain up to 30 characters.'),
  });
}

export function createBookCoverForm(defaultValues?: DeepPartial<BookCoverFormInput>): BookCoverForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onDirty',
    },
    defaultValues,
    schema: createBookCoverFormSchema(),
  });
}
