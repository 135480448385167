import { ReactNode } from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingAddressCountry } from '@remento/types/shipping';

import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';

import { BookPreviewScaler } from '../BookPreviewScaler/BookPreviewScaler';

import {
  BookPreviewContainer,
  StyledBookOrderSummary,
  SubtotalWrapper,
  SummaryContent,
  SummaryTable,
  SummaryTableItem,
  SummaryTableItemLabelWrapper,
} from './BookOrderSummary.styles';

export interface BookOrderSummaryProps {
  quantity: number;
  hasExtraFee: boolean;
  credits: number;
  ebook: boolean;
  ebookAlreadyPurchased: boolean;
  shippingCountry: ShippingAddressCountry;
  BookCoverPreview: ReactNode;
}

const BOOK_PRICE = 69;
const EXTRA_FREE_PRICE = 30;
const EBOOK_PRICE = 49.99;

function calculateShippingFees(quantity: number, country: ShippingAddressCountry): number {
  if (country === ShippingAddressCountry.US || quantity === 0) {
    return 0;
  }
  const fee = country === ShippingAddressCountry.CA ? 25 : 35;
  return fee + (quantity - 1) * 5;
}

export function BookOrderSummary({
  quantity,
  hasExtraFee,
  credits,
  ebook,
  ebookAlreadyPurchased,
  shippingCountry,
  BookCoverPreview,
}: BookOrderSummaryProps) {
  const bookCost = quantity * BOOK_PRICE;
  const extraFee = hasExtraFee ? quantity * EXTRA_FREE_PRICE : 0;
  const creditsDiscount = credits * BOOK_PRICE;
  const ebookCost = ebook && ebookAlreadyPurchased == false ? EBOOK_PRICE : 0;
  const shippingFees = calculateShippingFees(quantity, shippingCountry);
  const subtotal = bookCost + extraFee - creditsDiscount + ebookCost + shippingFees;

  return (
    <StyledBookOrderSummary>
      <RMText style={{ letterSpacing: '1px' }} type="sans" size="xs" color="on-surface-primary" bold>
        ORDER SUMMARY
      </RMText>
      <SummaryContent>
        <BookPreviewContainer>
          <BookPreviewScaler height={200}>{BookCoverPreview}</BookPreviewScaler>
        </BookPreviewContainer>
        <SummaryTable>
          {quantity > 0 && (
            <SummaryTableItem>
              <RMTooltip message="It costs $69 per book">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Hardcover books ({quantity} {quantity === 1 ? 'item' : 'items'})
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${bookCost.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {quantity > 0 && hasExtraFee === true && (
            <SummaryTableItem>
              <RMTooltip message="Books between 201-380 pages incur a $30 fee">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Extra fee
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${extraFee.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {ebook && (
            <SummaryTableItem>
              <RMTooltip message="Your e-book will be delivered via email">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    E-book
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${(ebookAlreadyPurchased ? 0 : EBOOK_PRICE).toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {credits > 0 && (
            <SummaryTableItem>
              <SummaryTableItemLabelWrapper>
                <RMText type="sans" size="s" color="on-surface-primary">
                  Credits applied
                </RMText>
              </SummaryTableItemLabelWrapper>
              <RMText type="sans" size="s" color="on-surface-primary">
                -${creditsDiscount.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {shippingFees > 0 && (
            <SummaryTableItem>
              <SummaryTableItemLabelWrapper>
                <RMText type="sans" size="s" color="on-surface-primary">
                  Shipping & handling
                </RMText>
              </SummaryTableItemLabelWrapper>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${shippingFees.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
        </SummaryTable>

        <SubtotalWrapper>
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            Subtotal*
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            ${subtotal.toFixed(2)}
          </RMText>
        </SubtotalWrapper>

        <RMText type="sans" size="xs" color="on-surface-primary">
          {shippingCountry === ShippingAddressCountry.US
            ? '*Includes US shipping. Tax calculated at checkout.'
            : '*Tax calculated at checkout.'}
        </RMText>
      </SummaryContent>
    </StyledBookOrderSummary>
  );
}
