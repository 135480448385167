import { useCallback } from 'react';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faCrop, faRightLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import addPhotoIcon from '@/assets/learn-photo.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackCropper, openFilestackPicker } from '@/utils/filestack';

import {
  Action,
  ActionDivider,
  Actions,
  Photo,
  RemoveButton,
  StyledBookCoverPhotoUpload,
} from './BookCoverPhotoUpload.styles';

export interface BookCoverPhotoUploadProps {
  photoSrc: string | null;
  onChange: (photo: FilestackHandle | null) => void;
}

export function BookCoverPhotoUpload({ photoSrc, onChange }: BookCoverPhotoUploadProps) {
  const handleOpenPhotoPicker = useCallback(async () => {
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        onUploadDone: ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          onChange(filesUploaded[0]);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [onChange]);

  const handleCropPhoto = useCallback(() => {
    if (photoSrc === null) {
      return;
    }

    openFilestackCropper([photoSrc], {
      transformations: {
        crop: {},
      },
      onUploadDone: ({ filesUploaded }) => {
        if (!filesUploaded[0]) {
          return;
        }

        onChange(filesUploaded[0]);
      },
    });
  }, [onChange, photoSrc]);

  const handleRemovePhoto = useCallback(() => {
    onChange(null);
  }, [onChange]);

  if (photoSrc === null) {
    return (
      <RMButton leftIcon={addPhotoIcon} iconSize="large" background="dashed" onClick={handleOpenPhotoPicker}>
        Upload a photo
      </RMButton>
    );
  }

  return (
    <StyledBookCoverPhotoUpload>
      <RemoveButton
        icon={faTrash}
        tooltip={null}
        outline="strong"
        color="danger"
        size="sm"
        onClick={handleRemovePhoto}
      />
      <Photo src={photoSrc} alt="Cover photo" />
      <Actions>
        <Action onClick={handleOpenPhotoPicker}>
          <FontAwesomeIcon icon={faRightLeft} color="var(--primary)" />
          <RMText type="sans" size="xs" bold color="primary">
            Change photo
          </RMText>
        </Action>
        <ActionDivider />
        <Action>
          <FontAwesomeIcon icon={faCrop} color="var(--primary)" />
          <RMText type="sans" size="xs" bold color="primary" onClick={handleCropPhoto}>
            Crop photo
          </RMText>
        </Action>
      </Actions>
    </StyledBookCoverPhotoUpload>
  );
}
