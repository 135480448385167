import { CSSProperties } from 'react';

import { RMTooltip } from '../RMTooltip/RMTooltip';

import { StyledRMColorSwatchItem, SwatchColor } from './RMColorSwatchItem.styles';

interface RMColorSwatchItemProps {
  colorName: string;
  color: string;
  active: boolean;
  onClick?: () => void;
}

export function RMColorSwatchItem({ colorName, color, active, onClick }: RMColorSwatchItemProps) {
  return (
    <RMTooltip key={color} side="bottom" message={colorName}>
      <StyledRMColorSwatchItem style={{ '--color': color } as CSSProperties} data-active={active} onClick={onClick}>
        <SwatchColor />
      </StyledRMColorSwatchItem>
    </RMTooltip>
  );
}
