import { styled } from '@linaria/react';

export const StyledRMColorSwatchItem = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  padding: var(--spacing-2xs);
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &[data-active='true'] {
    border: 2px solid var(--on-surface-primary);
  }

  &:hover {
    background: var(--inverse-on-surface-primary);
  }
`;

export const SwatchColor = styled.div`
  background-color: var(--color);
  border-radius: 100%;
  height: 100%;
`;
