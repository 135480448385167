import { ReactNode } from 'react';

import LaptopSrc from '@/assets/laptop.svg';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { RMPage } from '../RMPage';

import { Container, Description, Laptop } from './DesktopOnly.styles';

export interface DesktopOnlyProps {
  Header: ReactNode;
}

export function DesktopOnly({ Header }: DesktopOnlyProps) {
  return (
    <RMPage.Root>
      {Header}
      <RMPage.Content overflow="hidden">
        <Container>
          <Laptop src={LaptopSrc} />
          <RMSpacer spacing="2xl" direction="column" />
          <RMText type="serif" size="l" color="on-surface-primary">
            A new mobile experience is on its way ✨
          </RMText>
          <RMSpacer spacing="md" direction="column" />
          <Description>
            <RMText type="sans" size="s" color="on-surface-primary">
              In the meantime, visit us on a desktop or laptop computer.
            </RMText>
          </Description>
        </Container>
      </RMPage.Content>
    </RMPage.Root>
  );
}
