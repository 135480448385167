export enum ShippingAddressCountry {
  US = 'US',
  CA = 'CA',
  GB = 'GB',
  AU = 'AU',
  NZ = 'NZ',
  IE = 'IE',
}

export const ShippingAddressCountryName: Record<ShippingAddressCountry, string> = {
  [ShippingAddressCountry.AU]: 'Australia',
  [ShippingAddressCountry.CA]: 'Canada',
  [ShippingAddressCountry.GB]: 'United Kingdom',
  [ShippingAddressCountry.IE]: 'Ireland',
  [ShippingAddressCountry.NZ]: 'New Zealand',
  [ShippingAddressCountry.US]: 'United States',
};

export interface ShippingAddress {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: ShippingAddressCountry;
}

export type ShippingAddressValidationErrorCode =
  | 'DATESTAMP.FORMAT.INVALID'
  | 'COUNTRY.CODE.INVALID'
  | 'STANDARDIZED.ADDRESS.NOTFOUND'
  | 'ACCOUNTVERIFICATION.ACCOUNT.NOTFOUND'
  | 'INVALID.SUITE.NUMBER'
  | 'SUITE.NUMBER.REQUIRED'
  | 'PARTIAL.STREET.MATCH'
  | 'MISSING.OR.AMBIGUOUS.DIRECTIONAL'
  | 'INTERPOLATED.STREET.ADDRESS'
  | 'RRHC.CONVERSION';

export interface FailedShippingAddressValidationResult {
  type: 'failed';
  errors: Array<{
    code: ShippingAddressValidationErrorCode;
    message: string;
  }>;
}

export interface ResolvedShippingAddressValidationResult {
  type: 'resolved';
  resolvedAddress: {
    streetLines: string[];
    city: string;
    stateOrProvinceCode: string;
    countryCode: string;
  };
  warnings: Array<{
    code: ShippingAddressValidationErrorCode;
    message: string;
  }>;
}

export type ShippingAddressValidationResult =
  | FailedShippingAddressValidationResult
  | ResolvedShippingAddressValidationResult;
