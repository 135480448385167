import { useMemo } from 'react';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookColor, BookColorNameMap, BookShippingAddress } from '@remento/types/book';
import AddressFormatter from 'i18n-postal-address';

import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMText } from '@/components/RMText/RMText';

import {
  ReviewSummaryListItem,
  ReviewSummaryListRoot,
  ShippingAddressText,
  StyledBookOrderCheckoutReview,
  SummaryEditButton,
  SummarySeparator,
  SummaryTitle,
} from './BookOrderCheckoutReview.styles';

export interface BookOrderCheckoutReviewProps {
  title: string;
  subtitle?: string | null;
  color: BookColor;
  includedStories: number;
  totalStories: number;
  pageCount: number;
  shippingAddress?: BookShippingAddress | null;
  reviewed: boolean;
  onReviewedChange: (reviewed: boolean) => void;
  onEditBook?: () => void;
  onEditShippingAddress: () => void;
}

export function BookOrderCheckoutReview({
  title,
  subtitle,
  color,
  includedStories,
  totalStories,
  pageCount,
  shippingAddress,
  reviewed,
  onReviewedChange,
  onEditBook,
  onEditShippingAddress,
}: BookOrderCheckoutReviewProps) {
  const formattedShippingAddress = useMemo(() => {
    if (shippingAddress == null) {
      return null;
    }
    const addressFormatter = new AddressFormatter({
      firstName: shippingAddress.recipientName,
      address1: shippingAddress.addressLine1,
      address2: shippingAddress.addressLine2 ?? undefined,
      city: shippingAddress.city,
      state: shippingAddress.state,
      postalCode: shippingAddress.zipCode,
      country: shippingAddress.country,
    });
    return addressFormatter.toString();
  }, [shippingAddress]);

  return (
    <StyledBookOrderCheckoutReview>
      <ReviewSummaryListRoot>
        {/* Book */}
        <ReviewSummaryListItem>
          <SummaryTitle>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Book information
            </RMText>
            {onEditBook != null && (
              <SummaryEditButton onClick={onEditBook}>
                <RMText type="sans" size="s" color="primary" bold underline>
                  Edit
                </RMText>
                <FontAwesomeIcon icon={faEdit} fontSize={16} />
              </SummaryEditButton>
            )}
          </SummaryTitle>
          <SummarySeparator />
          <RMText type="sans" size="s" color="on-surface-primary">
            Title: {title}
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            Subtitle: {subtitle}
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            Color: {BookColorNameMap[color]}
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            {includedStories} out of {totalStories} total stories included
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            Total pages: {pageCount}
          </RMText>
        </ReviewSummaryListItem>
        {/* Shipping */}
        {formattedShippingAddress != null && (
          <ReviewSummaryListItem>
            <SummaryTitle>
              <RMText type="sans" size="s" color="on-surface-primary" bold>
                Shipping address
              </RMText>
              <SummaryEditButton onClick={onEditShippingAddress}>
                <RMText type="sans" size="s" color="primary" bold underline>
                  Edit
                </RMText>
                <FontAwesomeIcon icon={faEdit} fontSize={16} />
              </SummaryEditButton>
            </SummaryTitle>
            <SummarySeparator />
            <ShippingAddressText type="sans" size="s" color="on-surface-primary">
              {formattedShippingAddress}
            </ShippingAddressText>
          </ReviewSummaryListItem>
        )}
      </ReviewSummaryListRoot>

      <RMPicker.Root>
        <RMPicker.Item
          label="I acknowledge that I have reviewed my stories and I am ready to print my book."
          value={'true'}
          selected={reviewed}
          onClick={() => {
            onReviewedChange(!reviewed);
          }}
        />
      </RMPicker.Root>
    </StyledBookOrderCheckoutReview>
  );
}
