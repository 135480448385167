import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  gap: var(--spacing-4xl);
  height: 100%;

  body[data-tablet='true'] & {
    flex-direction: column-reverse;
    gap: var(--spacing-2xl);
  }
`;

export const LeftPanel = styled.div`
  width: 65%;

  body[data-tablet='true'] & {
    width: 32.5rem;
    flex-grow: 1;
  }
`;

export const RightPanel = styled.div`
  width: 35%;
  min-width: 350px;
  display: flex;
  justify-content: end;
  align-items: baseline;

  body[data-tablet='true'] & {
    width: 32.5rem;
    justify-content: start;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
`;
