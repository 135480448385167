import { styled } from '@linaria/react';

import { RMLink } from '@/components/RMLink/RMLink';

export const Content = styled.div`
  position: relative;
  display: flex;
  gap: var(--spacing-4xl);

  body[data-mobile='true'] &,
  body[data-tablet='true'] & {
    flex-direction: column-reverse;
    gap: var(--spacing-2xl);
  }
`;

export const LeftPanel = styled.div`
  width: calc(45% - var(--spacing-4xl) / 2);
  min-width: 17rem;

  body[data-mobile='true'] &,
  body[data-tablet='true'] & {
    width: 100%;
    min-width: unset;
  }
`;

export const RightPanel = styled.div`
  width: calc(55% - var(--spacing-4xl) / 2);
  min-width: 25rem;

  body[data-mobile='true'] &,
  body[data-tablet='true'] & {
    width: 100%;
    min-width: unset;
  }
`;

export const OrderHistoryButton = styled(RMLink)`
  position: absolute;
  top: calc(var(--spacing-xl) * -1);
  right: 0;

  display: flex;
  gap: var(--spacing-xs);
  font-weight: 700;
  align-items: center;
`;
