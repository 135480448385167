import { PdfDocumentAssetAlternativeType, PdfDocumentMetadata } from '@remento/types/alternative';
import { StoryStatus } from '@remento/types/story';

import { useFormValue } from '@/modules/form/form';
import { useInputValue } from '@/modules/form/input';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useBookOrderQuery } from '@/services/api/book';
import { useFilterStoriesIds, useProjectStoriesQuery } from '@/services/api/story';

import { BookQuantityForm } from '../book-quantity.form';
import { BookShippingForm } from '../book-shipping.form';
import { BookOrderCheckoutReview } from '../components/BookOrderCheckoutReview/BookOrderCheckoutReview';

export interface BookReorderCheckoutReviewContainerProps {
  projectId: string;
  bookOrderId: string;
  reviewed: boolean;
  quantityForm: BookQuantityForm;
  shippingForm: BookShippingForm;
  onReviewedChange: (reviewed: boolean) => void;
  onEditShippingAddress: () => void;
}

export function BookReorderCheckoutReviewContainer({
  projectId,
  bookOrderId,
  quantityForm,
  shippingForm,
  reviewed,
  onReviewedChange,
  onEditShippingAddress,
}: BookReorderCheckoutReviewContainerProps) {
  // Queries
  const bookOrderQuery = useBookOrderQuery(bookOrderId);

  const storiesQuery = useProjectStoriesQuery(projectId);
  const recordedStoriesIds = useFilterStoriesIds(storiesQuery.data ?? null, StoryStatus.RECORDED);

  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookOrderQuery.data?.pdfAssetId);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    PdfDocumentAssetAlternativeType.PREVIEW,
  );
  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;

  // State
  const hardcoverQuantity = useInputValue(quantityForm, 'quantity') ?? 0;
  const shippingFormValue = useFormValue(shippingForm);

  if (bookOrderQuery.data == null) {
    return null;
  }

  return (
    <BookOrderCheckoutReview
      title={bookOrderQuery.data.title}
      subtitle={bookOrderQuery.data.subtitle}
      color={bookOrderQuery.data.color}
      includedStories={bookOrderQuery.data.stories.length}
      totalStories={recordedStoriesIds?.length ?? 0}
      pageCount={bookPageCount}
      shippingAddress={hardcoverQuantity > 0 ? shippingFormValue : null}
      reviewed={reviewed}
      onReviewedChange={onReviewedChange}
      onEditShippingAddress={onEditShippingAddress}
    />
  );
}
