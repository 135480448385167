import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: var(--color-spruce-40-8);
  padding: var(--spacing-md) 14px;
`;

export const MessageBoxIcon = styled(FontAwesomeIcon)`
  margin-top: 2px;
`;

export const MessageBoxBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
`;
