import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { InputController } from '@/modules/form/container';

import { BookQrCodeForm } from '../../book-qr-code.form';

import { Checkbox, CheckboxMessageWrapper, CheckboxWrapper } from './BookQrCodeFormDialog.styles';

export interface BookQrCodeFormDialogProps {
  form: BookQrCodeForm;
  onSave: () => void;
  onClose: () => void;
}

export function BookQrCodeFormDialog({ form, onSave, onClose }: BookQrCodeFormDialogProps) {
  return (
    <RMDialog.Root open onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header title="Adjust QR code preferences" rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Body>
          <CheckboxWrapper>
            <InputController form={form} path="printQrCode">
              {(props) => <Checkbox square size="sm" checked={props.value ?? true} onChange={props.onChange} />}
            </InputController>
            <CheckboxMessageWrapper>
              <RMText type="sans" size="s" color="on-surface-primary" bold>
                Print QR codes in my book
              </RMText>
              <RMText type="sans" size="s" color="on-surface-primary">
                When scanned, QR codes will bring readers to the original recording used to write each story.
              </RMText>
            </CheckboxMessageWrapper>
          </CheckboxWrapper>
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton background="primary" autoLoading onClick={onSave}>
            Save
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
