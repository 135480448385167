import { styled } from '@linaria/react';

export const StyledBookCoverFormView = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;
