import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PdfDocumentAssetAlternativeType, PdfDocumentMetadata } from '@remento/types/alternative';
import dayjs from 'dayjs';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import {
  BookReorderHeaderContainer,
  BookReorderHeaderStep,
} from '@/modules/book-builder/containers/BookReorderHeader.container';
import { getBookReorderQuantityPath } from '@/modules/routing';
import { useAlternativeFileUrl, useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useBookOrderQuery } from '@/services/api/book';

import {
  BookViewer,
  BookWrapper,
  Content,
  DownloadButton,
  LeftPanel,
  Link,
  NextButton,
  RightPanel,
} from './BookReorderPreviewPage.styles';

export function BookReorderPreviewPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookOrderId = params.bookOrderId ?? '';

  // Services
  const navigate = useNavigate();

  // Queries
  const bookOrderQuery = useBookOrderQuery(bookOrderId);
  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookOrderQuery.data?.pdfAssetId);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    PdfDocumentAssetAlternativeType.PREVIEW,
  );
  const bookPreviewUrl = useAlternativeFileUrl(bookPreviewAlternative?.id);

  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;

  // State
  const { quantityForm } = useBookOrderContext();

  // Learn more state
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);

  // Callbacks
  const handleNext = useCallback(() => {
    navigate(getBookReorderQuantityPath(projectId, bookOrderId));
  }, [bookOrderId, navigate, projectId]);

  if (bookPreviewAlternative == null) {
    return <PageLoader />;
  }

  return (
    <RMPage.Root>
      <BookReorderHeaderContainer
        projectId={projectId}
        bookOrderId={bookOrderId}
        step={BookReorderHeaderStep.Preview}
        quantityForm={quantityForm}
      />
      <RMPage.Content overflow="hidden">
        <Content>
          <LeftPanel>
            <RMText type="serif" size="l" color="on-surface-primary">
              Preview your book
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary">
              Please review the contents of your book to confirm the stories and order. Your book has a total of{' '}
              {bookPageCount} pages and was last updated {dayjs(bookPreviewAlternative.audit.create.date).fromNow()}.
              <br />
              <br />
              Learn more about how QR codes are generated <Link onClick={() => setLearnMoreOpen(true)}>here</Link>.
            </RMText>

            <RMSpacer spacing="2xl" direction="column" />

            <NextButton background="primary" autoLoading onClick={handleNext}>
              Next
            </NextButton>
          </LeftPanel>
          <RightPanel>
            {bookPreviewUrl && (
              <BookWrapper>
                <BookViewer data={bookPreviewUrl} type="application/pdf" width="100%" height="100%" />
                <DownloadButton href={bookPreviewUrl} target="_blank">
                  <FontAwesomeIcon icon={faDownload} color="var(--primary)" />
                  <RMText type="sans" size="xs" color="primary" bold>
                    Download preview
                  </RMText>
                </DownloadButton>
              </BookWrapper>
            )}
          </RightPanel>
        </Content>
      </RMPage.Content>

      {/* Learn more dialog */}
      <RMDialog.Root open={learnMoreOpen} onClose={() => setLearnMoreOpen(false)}>
        <RMDialog.Content>
          <RMDialog.Header
            title="About Remento’s QR codes"
            rightAdornment={<RMCloseButton onClick={() => setLearnMoreOpen(false)} />}
          />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              When scanned, QR codes printed in Remento Books bring readers directly to the recording used to write each
              chapter. That way no future reader will ever be without the written story - or the voice behind. Learn
              more about how Remento uses QR codes via our{' '}
              <Link
                href="https://help.remento.co/en/articles/8915122-how-does-the-remento-book-work#h_1b2421f60f"
                target="_blank"
              >
                FAQ
              </Link>
              .
            </RMText>
          </RMDialog.Body>
          <RMDialog.Footer>
            <RMButton background="primary" onClick={() => setLearnMoreOpen(false)}>
              Close
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </RMPage.Root>
  );
}
