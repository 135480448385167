import { useCallback, useMemo, useState } from 'react';

import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { submitForm } from '@/modules/form/form';
import { useServices } from '@/Services';
import { useBookQuery } from '@/services/api/book';
import { captureException } from '@/utils/captureException';

import { createBookQrCodeForm } from '../book-qr-code.form';
import { BookQrCodeConfirmationDialog } from '../components/BookQrCodeConfirmationDialog/BookQrCodeConfirmationDialog';
import { BookQrCodeFormDialog } from '../components/BookQrCodeFormDialog/BookQrCodeFormDialog';

export interface BookQrCodeFormDialogContainerProps {
  bookId: string;
  onClose: () => void;
}

export function BookQrCodeFormDialogContainer({ bookId, onClose }: BookQrCodeFormDialogContainerProps) {
  const { entityCacheManagerService, bookService } = useServices();

  const bookQuery = useBookQuery(bookId);
  const form = useMemo(
    () => createBookQrCodeForm({ printQrCode: (bookQuery.data?.hideQrCode ?? false) == false }),
    [bookQuery.data?.hideQrCode],
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSave = useCallback(async () => {
    const book = bookQuery.data;
    if (book == null) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    await submitForm(form, async (formData) => {
      if (formData.printQrCode == false) {
        setShowConfirmation(true);
        return;
      }

      try {
        await entityCacheManagerService.mutate([bookService.createSetBookHideQrCodeMutation(book, false)]);
        onClose();
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [bookQuery.data, bookService, entityCacheManagerService, form, onClose]);

  const handleConfirmHideQrCode = useCallback(async () => {
    const book = bookQuery.data;
    if (book == null) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    try {
      await entityCacheManagerService.mutate([bookService.createSetBookHideQrCodeMutation(book, true)]);
      onClose();
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookQuery.data, bookService, entityCacheManagerService, onClose]);

  if (showConfirmation) {
    return <BookQrCodeConfirmationDialog onConfirm={handleConfirmHideQrCode} onCancel={onClose} />;
  }

  return <BookQrCodeFormDialog form={form} onSave={handleSave} onClose={onClose} />;
}
